import React from 'react'
import styled from 'styled-components'

const Caution: React.FC = () => {
  return (
    <Container>
      <p>
        LINEのトーク画面から
        <br />
        アクセスしてください
      </p>
    </Container>
  )
}

const Container = styled.nav`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 200;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.2em;
  background-color: #f8f8ec;
`
export default Caution
