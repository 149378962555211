import React, {
  useContext,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from 'react'
import { DetailContext } from '../Context'
import axios from 'axios'
import { PieChart, Pie, Cell } from 'recharts'
import styled from 'styled-components'
import { Button } from 'antd'
import { LoadingOutlined } from '@ant-design/icons'
import tapHere from '../img/tap_here.svg'
import tapHereCircle from '../img/tap_here_circle.svg'

const Log: React.FC = () => {
  const { apiUrl, userId, setTitle } = useContext(DetailContext)
  const [count, setCount] = useState<any>({
    recording_date: null,
    user_id: userId,
    cancel: 0,
    base_count: 0,
    roop_count: 0,
  })
  const [loading, setLoading] = useState<boolean>(true) // 初期読み込み中フラグ
  const [ratio, setRatio] = useState<any>([{ value: 0 }, { value: 20 }]) // 記録回数円グラフ用に加工
  const [check, setCheck] = useState<boolean>(false) // 同日にチェックしているか
  const [showCancel, setShowCancel] = useState<boolean>(false) // キャンセルボタンの表示・非表示
  const [windowWidth, setWindowWidth] = useState<number>(400) // 画面幅に合わせる
  const [showCompleteDialog, setShowCompleteDialog] = useState<boolean>(false) // 記録完了時のダイアログ
  const [showConfirmDialog, setShowConfirmDialog] = useState<boolean>(false) // キャンセル確認ダイアログ
  const [showCancelDialog, setShowCancelDialog] = useState<boolean>(false) // キャンセル時のダイアログ
  const [submitting, setSubmitting] = useState<boolean>(false) // 処理中フラグ
  const wrapper = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (wrapper.current?.clientWidth) {
      setWindowWidth(wrapper.current?.clientWidth)
    }
    setTitle('マインドフルネス日誌')
  }, [])

  // カウント回数の取得
  useLayoutEffect(() => {
    fetchCount()
  }, [userId])

  // キャンセルボタンの表示切替
  useLayoutEffect(() => {
    setTime()
    if (check && !count.cancel) {
      setShowCancel(true)
    } else {
      setShowCancel(false)
    }
  }, [count, check])

  async function shapeCount(data: any) {
    const baseCount = Number(data.base_count)
    const roopCount = Number(data.roop_count)
    const recordingDate = new Date(data.recording_date)
    const cancel = Number(data.cancel) === 1 ? true : false
    setCount({
      ...count,
      recording_date: recordingDate,
      cancel: cancel,
      base_count: baseCount,
      roop_count: roopCount,
    })
    await new Promise<void>((resolve: (value?: any) => void) => {
      setTime()
      resolve()
    })
    setChartData(baseCount, roopCount)
  }

  async function fetchCount() {
    setTime()
    try {
      const res = await axios.get(
        `${apiUrl}/db/php/mindfulness_get_count_info.php?user_id=${userId}`
      )
      setLoading(false)
      if (res.status === 200) {
        shapeCount(res.data)
      }
    } catch (error) {
      console.log(error)
    }
  }

  function toggleCount(): void {
    if (showCancel) {
      setShowConfirmDialog(true)
    } else {
      handleSubmit()
    }
  }

  function setChartData(baseCount: number, roopCount: number): void {
    if (baseCount % 20 === 0 && roopCount >= 1 && check) {
      setRatio([{ value: 20 }, { value: 0 }])
    } else {
      setRatio([{ value: baseCount % 20 }, { value: 20 - (baseCount % 20) }])
    }
  }

  function handleSubmit(): void {
    if (showCancel || submitting) {
      return
    }
    setSubmitting(true)
    submitCount()
  }

  async function submitCount() {
    try {
      const baseCount: number = count.base_count + 1
      const roopCount: number =
        baseCount % 20 === 0 ? count.roop_count + 1 : count.roop_count

      const res = await axios.get(
        `${apiUrl}/db/php/mindfulness_set_count_info.php`,
        {
          params: {
            user_id: userId,
            cancel: 0,
            base_count: baseCount,
            roop_count: roopCount,
          },
        }
      )
      shapeCount(res.data)
      console.log(`react:${roopCount}, php:${res.data}`)

      await axios.get(`${apiUrl}/push_recording_message.php`, {
        params: {
          user_id: userId,
          cancel: 0,
        },
      })
      setShowCompleteDialog(true)
    } catch (error) {
      console.log(error)
    }
  }

  async function cancelCount() {
    try {
      const baseCount: number = count.base_count - 1
      const roopCount: number =
        baseCount !== 0 && baseCount % 20 === 0
          ? count.roop_count - 1
          : count.roop_count
      const res = await axios.get(
        `${apiUrl}/db/php/mindfulness_set_count_info.php`,
        {
          params: {
            user_id: userId,
            cancel: 1,
            base_count: baseCount,
            roop_count: roopCount,
          },
        }
      )
      shapeCount(res.data)
      console.log(`react:${roopCount}, php:${res.data}`)

      await axios.get(`${apiUrl}/push_recording_message.php`, {
        params: {
          user_id: userId,
          cancel: 1,
        },
      })
      setShowCancelDialog(true)
    } catch (error) {
      console.error()
    }
  }

  function setTime(): void {
    const now = new Date()
    const prev: number = now.getHours() >= 8 ? 0 : 1
    const startTime = new Date(
      now.getFullYear(), // 年
      now.getMonth(), // 月
      now.getDate() - prev, // 日
      8 // 時間（8時）
    )
    const isToday: boolean = startTime <= count.recording_date ? true : false
    setCheck(isToday)

    if (count.baseCount % 20 === 0 && count.roopCount >= 1 && isToday) {
      setRatio([{ value: 20 }, { value: 0 }])
    }
  }

  const tapImage: JSX.Element = (
    <>
      <img src={tapHereCircle} alt="ここをタップ" className="tap-here circle" />
      <img src={tapHere} alt="ここをタップ" className="tap-here" />
    </>
  )

  const completeDialog: JSX.Element = (
    <Dialog
      onClick={() => {
        setShowCompleteDialog(false)
        setSubmitting(false)
      }}
    >
      <div className="wrapper">
        <p>本日の記録をつけました！</p>
        <div>
          <Button
            type="primary"
            onClick={() => {
              setShowCompleteDialog(false)
              setSubmitting(false)
            }}
          >
            閉じる
          </Button>
        </div>
      </div>
    </Dialog>
  )

  const confirmDialog: JSX.Element = (
    <Dialog onClick={() => setShowConfirmDialog(false)}>
      <div className="wrapper">
        <p>本日の記録を取り消しますか？</p>
        <div>
          <Button
            type="primary"
            style={{ marginRight: '10px' }}
            onClick={() => {
              setShowConfirmDialog(false)
              setShowCancelDialog(true)
              cancelCount()
            }}
          >
            はい
          </Button>
          <Button onClick={() => setShowConfirmDialog(false)}>
            キャンセル
          </Button>
        </div>
      </div>
    </Dialog>
  )

  const cancelDialog: JSX.Element = (
    <Dialog onClick={() => setShowCancelDialog(false)}>
      <div className="wrapper">
        <p>本日の記録を取り消しました</p>
        <div>
          <Button type="primary" onClick={() => setShowCancelDialog(false)}>
            閉じる
          </Button>
        </div>
      </div>
    </Dialog>
  )

  const submitBtn: JSX.Element = (
    <ButtonWrap>
      <Button block type="primary" onClick={submitCount}>
        記録する（テスト用）
      </Button>
      <Button block type="primary" onClick={cancelCount}>
        キャンセル（テスト用）
      </Button>
    </ButtonWrap>
  )

  if (loading) {
    return (
      <Container ref={wrapper}>
        <div className="chart-wrap">
          <LoadingOutlined />
        </div>
      </Container>
    )
  }
  return (
    <Container ref={wrapper}>
      <div className="chart-wrap">
        <PieChart
          className="pie-chart"
          width={windowWidth}
          height={windowWidth}
        >
          <Pie
            data={ratio}
            dataKey="value"
            cx="50%"
            cy="50%"
            outerRadius={windowWidth * 0.4}
            fill="#ffffff"
            isAnimationActive={false}
            onClick={toggleCount}
          />
          <Pie
            data={ratio}
            dataKey="value"
            cx="50%"
            cy="50%"
            innerRadius={windowWidth * 0.4}
            outerRadius={windowWidth * 0.45}
            fill="#eaf5ee"
            strokeWidth="0"
            isAnimationActive={false}
          />
          <Pie
            data={ratio}
            dataKey="value"
            cx="50%"
            cy="50%"
            startAngle={90}
            endAngle={-270}
            innerRadius={windowWidth * 0.4}
            outerRadius={windowWidth * 0.45}
            fill="#82ca9d"
          >
            <Cell key="cell-0" fill="#82ca9d" strokeWidth="0" />
            <Cell key="cell-1" fill="#eaf5ee" strokeWidth="0" />
          </Pie>
        </PieChart>
        <div className="text">
          <p>マインドフルネス実施記録</p>
          <p className="text-num">{ratio[0].value} / 20 回</p>
        </div>
        {!showCancel && tapImage}
      </div>
      {submitBtn}
      {showCompleteDialog && completeDialog}
      {showConfirmDialog && confirmDialog}
      {showCancelDialog && cancelDialog}
    </Container>
  )
}

const Container = styled.nav`
  .chart-wrap {
    height: calc(100vh - 110px);
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    .text {
      width: 100%;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      text-align: center;
      font-size: 16px;
      pointer-events: none;
      p {
        margin-bottom: 5px;
      }
      &-num {
        color: #444;
        font-size: 21px;
        font-weight: 600;
      }
      Button {
        position: absolute;
        top: 120%;
        left: 50%;
        transform: translateX(-50%);
      }
    }
    .tap-here {
      position: absolute;
      top: 60%;
      left: 55%;
      width: 30%;
      pointer-events: none;
      &.circle {
        animation-name: fade;
        animation-duration: 2.5s;
        animation-timing-function: linear;
        animation-iteration-count: infinite;
      }
    }
  }
  .pie-chart {
    margin: 0 auto;
  }

  @keyframes fade {
    0% {
      opacity: 0.5;
    }
    35% {
      opacity: 0.9;
    }
    50% {
      opacity: 0.9;
    }
    85% {
      opacity: 0.5;
    }
    100% {
      opacity: 0.5;
    }
  }
`

const Dialog = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(10, 10, 10, 0.3);
  .wrapper {
    width: 90%;
    padding: 30px 10px;
    border-radius: 5px;
    text-align: center;
    background-color: #ffffff;
    box-shadow: 0 0 5px rgba(30, 30, 30, 0.3);
    p {
      padding: 30px 0;
      margin-bottom: 20px;
    }
  }
`
const ButtonWrap = styled.div`
  position: fixed;
  left: 50%;
  bottom: 0;
  transform: translateX(-50%);
  width: 200px;
  height: 80px;
  padding-bottom: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`
export default Log
