import React, { useContext, useEffect, useState } from 'react'
import { DetailContext } from '../Context'
import { Link, withRouter } from 'react-router-dom'
import axios from 'axios'
import styled, { css } from 'styled-components'
import { Form, Input, Radio, Select, Button } from 'antd'
const { Option } = Select

const Entry: React.FC = () => {
  const [gender, setGender] = useState<number | null>(null)
  const [age, setAge] = useState<number | null>(null)
  const [companyId, setCompanyId] = useState<string | null>(null)
  const [active, setActive] = useState<boolean>(false)
  const [modal, setModal] = useState<boolean>(false)
  const [error, setError] = useState<boolean>(false)
  const { apiUrl, userId, setTitle, getMemberData } = useContext(DetailContext)

  useEffect(() => {
    setTitle('新規登録')
  }, [])

  useEffect(() => {
    if (gender && age && companyId) {
      setActive(true)
    } else {
      setActive(false)
    }
  })

  async function handleSubmit(event: any) {
    event.preventDefault()
    if (!active) {
      return
    }

    const user: any = {
      gender: gender,
      age: age,
      company_id: companyId,
      user_id: userId,
    }
    try {
      await axios.get(`${apiUrl}/db/php/mindfulness_set_member_info.php`, {
        params: user,
      })
      setModal(true)
      getMemberData()
    } catch {
      setError(true)
    }
  }

  const dialog: JSX.Element = (
    <Container>
      <div className="wrapper">
        <p>ユーザー登録が完了しました！</p>
        <div>
          <Link to={`/check/?user_id=${userId}`}>
            <Button type="primary">マインドチェックへ</Button>
          </Link>
        </div>
      </div>
    </Container>
  )

  const message: JSX.Element = (
    <Message>
      VR IDが違います。
      <br />
      配布されたVR IDをご確認ください。
    </Message>
  )

  return (
    <div>
      {error && message}
      <Form onSubmitCapture={(event: any) => handleSubmit(event)}>
        <Table>
          <tbody>
            <tr>
              <th>性別</th>
              <td>
                <Form.Item style={{ marginBottom: '0' }}>
                  <Radio.Group
                    onChange={(event: any) => setGender(event.target.value)}
                  >
                    <Radio value="男性">男性</Radio>
                    <Radio value="女性">女性</Radio>
                    <Radio value="その他">その他</Radio>
                  </Radio.Group>
                </Form.Item>
              </td>
            </tr>

            <tr>
              <th>年代</th>
              <td>
                <Form.Item style={{ marginBottom: '0' }}>
                  <Select onChange={(event: any) => setAge(event)}>
                    <Option value="10代">10代</Option>
                    <Option value="20代">20代</Option>
                    <Option value="30代">30代</Option>
                    <Option value="40代">40代</Option>
                    <Option value="50代">50代</Option>
                    <Option value="60代">60代</Option>
                    <Option value="70代以上">70代以上</Option>
                  </Select>
                </Form.Item>
              </td>
            </tr>

            <tr>
              <th>VR ID</th>
              <td>
                <Form.Item style={{ marginBottom: '0' }}>
                  <Input
                    name="company_id"
                    onChange={(event) => setCompanyId(event.target.value)}
                  />
                </Form.Item>
              </td>
            </tr>
          </tbody>
        </Table>

        <ButtonWrap isActive={active}>
          <Button block type="primary" htmlType="submit" className="submit">
            登録
          </Button>
        </ButtonWrap>

        <Policy style={{ textAlign: 'center' }}>
          登録を行うことでSeleneの<a href="/">サービス利用規約</a>に<br />
          同意したものとみなされます。
        </Policy>
      </Form>
      {modal && dialog}
    </div>
  )
}

const Table = styled.table`
  width: 100%;
  tr {
    height: 50px;
    vertical-align: middle;
  }
  th {
    width: 30%;
    font-weight: normal;
    text-align: left;
  }
  td {
    width: 70%;
  }
`

const Policy = styled.div`
  margin-top: 20px;
  font-size: 0.7em;
`

const ButtonWrap = styled.div<{ isActive: boolean }>`
  width: 80%;
  margin: 0 auto;
  margin-top: 10px;
  ${(props) =>
    props.isActive
      ? ''
      : css`
          .submit {
            background-color: #ccc !important;
            pointer-events: none;
          }
        `}
`
const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(10, 10, 10, 0.7);
  .wrapper {
    width: 90%;
    padding: 30px 10px;
    border-radius: 5px;
    text-align: center;
    background-color: #ffffff;
    p {
      padding: 30px 0;
      margin-bottom: 20px;
    }
  }
`
const Message = styled.div`
  width: 90%;
  padding: 5px;
  margin: 0 auto;
  margin-bottom: 10px;
  border: 1px solid #ff4d4f;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ff4d4f;
  background-color: #fff2f0;
`

export default withRouter(Entry)
