import React, { useContext, useEffect, useRef, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { Link } from 'react-router-dom'
import { DetailContext } from '../Context'
import {
  Radar,
  RadarChart,
  PolarGrid,
  PolarAngleAxis,
  PolarRadiusAxis,
} from 'recharts'
import styled from 'styled-components'
import { Button } from 'antd'

const Detail: React.FC = () => {
  const { state } = useLocation<any>()
  const detailData = state

  const [masterData, setMasterData] = useState<any>({})
  const [profileData, setProfileData] = useState<Array<any>>([])
  const [windowWidth, setWindowWidth] = useState<number>(400)
  const { userId, formInfo, setTitle } = useContext(DetailContext)
  const master: any = { sum: 0 }
  const profile: any = {}
  const wrapper = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (wrapper.current?.clientWidth) {
      setWindowWidth(wrapper.current?.clientWidth)
    }

    if (formInfo.length === 0 || detailData === {}) {
      return
    }
    formInfo.forEach((form) => {
      const major = `${form.major_category_id}`
      const minor = `${form.minor_category}`
      if (!master[major]) {
        master[major] = 0
      }
      if (!profile[minor]) {
        profile[minor] = 0
      }
      master[major] += Number(detailData[form.question_id])
      profile[minor] += Number(detailData[form.question_id])
      master.sum += Number(detailData[form.question_id])
    })

    const array: any = []
    for (const key in profile) {
      array.push({
        category: key,
        point: profile[key],
        fullMark: 20,
      })
    }

    setMasterData(master)
    setProfileData(array)
    setTitle('評価結果')
  }, [formInfo, detailData])

  return (
    <Container ref={wrapper}>
      <div>
        <h2>{detailData.date}</h2>
        <h3>評価結果</h3>
        <div className="table-wrap">
          <table>
            <thead>
              <tr className="underline">
                <th>項目</th>
                <td>評価得点</td>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th>マインドフルネス度</th>
                <td>{masterData.major_category_001} / 40点</td>
              </tr>
              <tr>
                <th>メンタルヘルス度</th>
                <td>{masterData.major_category_002} / 40点</td>
              </tr>
              <tr className="underline">
                <th>フィジカルヘルス度</th>
                <td>{masterData.major_category_003} / 40点</td>
              </tr>
              <tr>
                <th>合計</th>
                <td>{masterData.sum} / 120点</td>
              </tr>
            </tbody>
          </table>
        </div>

        <h3>プロファイル</h3>
        <RadarChart
          width={windowWidth}
          height={windowWidth * 0.7}
          cx="50%"
          cy="50%"
          outerRadius="80%"
          data={profileData}
          className="chart"
        >
          <PolarGrid />
          <PolarAngleAxis dataKey="category" />
          <PolarRadiusAxis domain={[0, 20]} />
          <Radar
            name="Mike"
            dataKey="point"
            stroke="#C6E191"
            fill="#C6E191"
            fillOpacity={0.6}
          />
        </RadarChart>
      </div>

      <ButtonWrap>
        <Button block>
          <Link to={`/?user_id=${userId}`}>一覧に戻る</Link>
        </Button>
      </ButtonWrap>
    </Container>
  )
}

const Container = styled.nav`
  height: 100%;
  min-height: calc(100vh - 110px);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  h2 {
    font-size: 18px;
    text-align: center;
  }
  h3 {
    width: 50%;
    padding: 2px 0;
    margin-bottom: 0;
    color: #ffffff;
    text-align: center;
    background-color: #41bf78;
  }
  .table-wrap {
    width: 100%;
    padding: 5px 10px;
    margin: 0 auto;
    margin-bottom: 20px;
    font-size: 14px;
    background-color: #eaf5ee;
  }
  table {
    width: 100%;
    th {
      width: 60%;
      padding: 2px 5%;
    }
    td {
      width: 40%;
      padding: 2px 7%;
    }
    thead {
      text-align: center;
    }
    tbody {
      th {
        text-align: left;
      }
      td {
        text-align: right;
      }
    }
    .underline {
      border-bottom: 1px solid #ccc;
    }
  }
  .chart {
    background-color: #eaf5ee;
  }
`
const ButtonWrap = styled.div`
  width: 80%;
  max-width: 400px;
  padding-top: 20px;
  margin: 0 auto;
`

export default Detail
