import React, { useContext, useEffect, useRef, useState } from 'react'
import { DetailContext } from '../Context'
import { Link } from 'react-router-dom'
import { LineChart, Line, XAxis, YAxis, CartesianGrid } from 'recharts'
import styled from 'styled-components'
import { Button } from 'antd'
import { SettingOutlined } from '@ant-design/icons'

type Props = {
  data: Array<any>
}

const Graph: React.FC<Props> = ({ data }) => {
  const [latest, toggleLatest] = useState<boolean>(true)
  const [view, setView] = useState<Array<any>>(data)
  const [windowWidth, setWindowWidth] = useState<number>(400)
  const { userId } = useContext(DetailContext)

  const wrapper = useRef<HTMLDivElement>(null)

  useEffect(() => {
    const array: Array<any> = []
    if (latest) {
      const length: number = data.length
      for (let i = length - 5; i < length; i++) {
        if (data[i]) {
          const object: any = Object.create(data[i])
          object.date = object.date.split(' ')[0]
          array.push(object)
        }
      }
    } else {
      data.forEach((el) => {
        const object: any = Object.create(el)
        object.date = object.date.split(' ')[0]
        array.push(object)
      })
    }
    setView(array)
    if (wrapper.current?.clientWidth) {
      setWindowWidth(wrapper.current?.clientWidth)
    }
  }, [data, latest])

  let sort: JSX.Element
  if (latest) {
    sort = (
      <HeadLeft>
        <Button size="small" type="primary" style={{ marginRight: '10px' }}>
          最新5件
        </Button>
        <Button
          size="small"
          onClick={() => {
            toggleLatest(false)
          }}
        >
          全て
        </Button>
      </HeadLeft>
    )
  } else {
    sort = (
      <HeadLeft>
        <Button
          size="small"
          onClick={() => {
            toggleLatest(true)
          }}
          style={{ marginRight: '10px' }}
        >
          最新5件
        </Button>
        <Button size="small" type="primary">
          全て
        </Button>
      </HeadLeft>
    )
  }

  return (
    <div ref={wrapper}>
      <Head>
        {sort}
        <div>
          {/* <Link to={`/log/?user_id=${userId}`}>
            <Button
              shape="circle"
              icon={<CarryOutOutlined />}
              style={{ marginRight: '8px' }}
            />
          </Link> */}
          <Link to={`/setting/?user_id=${userId}`}>
            <Button shape="circle" icon={<SettingOutlined />} />
          </Link>
        </div>
      </Head>
      <LineChart
        width={windowWidth}
        height={320}
        data={view}
        margin={{
          top: 5,
          right: 30,
          left: 0,
          bottom: 5,
        }}
      >
        <CartesianGrid />
        <XAxis dataKey="date" />
        <YAxis />
        {/* <Tooltip /> */}
        {/* <Legend /> */}
        <Line dataKey="sum" stroke="#41bf78" />
      </LineChart>
    </div>
  )
}

const Head = styled.nav`
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .ant-btn-sm {
    height: 28px;
    padding: 0 10px;
  }
  .ant-btn-icon-only {
    width: 36px;
    height: 36px;
  }
`

const HeadLeft = styled.nav`
  display: flex;
`
export default Graph
