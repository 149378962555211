import React, { useContext, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { DetailContext } from '../Context'
import styled from 'styled-components'
import { List, Button } from 'antd'
import { SettingOutlined, RightOutlined } from '@ant-design/icons'

type Props = {
  data: Array<any>
}

const Table: React.FC<Props> = ({ data }) => {
  const [asc, toggleAsc] = useState<boolean>(true)
  const [view, setView] = useState<Array<any>>(data)
  const { userId } = useContext(DetailContext)

  useEffect(() => {
    // asc = true で新しい順に並び替え
    if (asc) {
      const array: Array<any> = []
      const length: number = data.length
      for (let i = 1; i <= length; i++) {
        array.push(data[length - i])
      }
      setView(array)
    } else {
      setView(data)
    }
  }, [data, asc])

  let sort: JSX.Element
  if (asc) {
    sort = (
      <HeadLeft>
        <Button size="small" type="primary" style={{ marginRight: '10px' }}>
          新しい順
        </Button>
        <Button
          size="small"
          onClick={() => {
            toggleAsc(false)
          }}
        >
          古い順
        </Button>
      </HeadLeft>
    )
  } else {
    sort = (
      <HeadLeft>
        <Button
          size="small"
          onClick={() => toggleAsc(true)}
          style={{ marginRight: '10px' }}
        >
          新しい順
        </Button>
        <Button size="small" type="primary">
          古い順
        </Button>
      </HeadLeft>
    )
  }

  let content: JSX.Element
  if (view) {
    content = (
      <List
        size="small"
        itemLayout="vertical"
        pagination={{ pageSize: 5 }}
        dataSource={view}
        renderItem={(item) => (
          <List.Item>
            <ItemLink
              to={{
                pathname: `/detail`,
                search: `?user_id=${userId}`,
                state: item,
              }}
            >
              <span className="date">{item.date}</span>
              <span>{item.sum}点</span>
              <RightOutlined />
            </ItemLink>
          </List.Item>
        )}
      />
    )
  } else {
    content = <p>データがありません。</p>
  }

  return (
    <Container>
      <div className="head">
        {sort}
        <div>
          <Link to={`/setting/?user_id=${userId}`}>
            <Button shape="circle" icon={<SettingOutlined />} />
          </Link>
        </div>
      </div>

      {content}
    </Container>
  )
}

const Container = styled.div`
  padding-bottom: 20px;
  .ant-btn-sm {
    height: 28px;
    padding: 0 10px;
  }
  .ant-btn-icon-only {
    width: 36px;
    height: 36px;
  }
  .ant-list {
    line-height: 2.1;
    font-size: 16px;
  }
  .ant-list-items {
    height: 250px;
  }
  .ant-list-pagination {
    text-align: center;
  }
  .head {
    margin-bottom: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
`

const HeadLeft = styled.nav`
  display: flex;
`

const ItemLink = styled(Link)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: initial;
  .date {
    width: 140px;
  }
`

export default Table
