import React from 'react'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import { DetailProvider } from './Context'
import Header from './components/Header'
import Home from './components/Home'
import Entry from './components/Entry'
import Setting from './components/Setting'
import Detail from './components/Detail'
import Check from './components/Check'
import Log from './components/Log'
import styled from 'styled-components'
import './App.css'

const App: React.FC = () => {
  return (
    <Router basename="/">
      <Wrapper>
        <div className="main">
          <Switch>
            <DetailProvider>
              <Header />
              <Route exact path="/" render={() => <Home />} />
              <Route exact path="/entry" render={() => <Entry />} />
              <Route exact path="/setting" render={() => <Setting />} />
              <Route exact path="/detail" render={() => <Detail />} />
              <Route exact path="/check" render={() => <Check />} />
              <Route exact path="/log" render={() => <Log />} />
            </DetailProvider>
          </Switch>
        </div>
      </Wrapper>
    </Router>
  )
}

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  background-color: #f8f8ec;
  .main {
    max-width: 500px;
    min-height: 100vh;
    padding: 70px 20px 40px;
    margin: 0 auto;
  }

  --antd-wave-shadow-color: #41bf78;

  .ant-btn {
    &:hover {
      border-color: #d9d9d9;
      color: rgba(0, 0, 0, 0.85);
    }
  }
  .ant-btn-primary {
    background-color: #41bf78;
    border-color: transparent;
    &:hover {
      background-color: #41bf78;
      border-color: transparent;
      color: #ffffff;
    }
  }
  .ant-pagination-prev,
  .ant-pagination-next,
  .ant-pagination-item {
    min-width: 36px;
    height: 36px;
    font-size: 16px;
    line-height: 34px;
    &:hover {
      border-color: #41bf78;
      a {
        color: #41bf78;
      }
    }
    &-active {
      border-color: #41bf78;
      a {
        color: #41bf78;
      }
    }
  }
  .ant-pagination-prev:hover {
    .ant-pagination-item-link {
      color: inherit;
      border-color: #d9d9d9;
    }
  }
  .ant-pagination-next:hover {
    .ant-pagination-item-link {
      color: inherit;
      border-color: #d9d9d9;
    }
  }
  .ant-pagination-disabled {
    color: rgba(0, 0, 0, 0.25);
    border-color: #d9d9d9;
    cursor: not-allowed;
  }
  .ant-radio-checked .ant-radio-inner {
    border-color: #41bf78;
  }
  .ant-radio-inner::after {
    background-color: #41bf78;
  }
`

export default App
