import React, { useContext } from 'react'
import { DetailContext } from '../Context'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { Button } from 'antd'

type Props = {
  item: any
}

const Complete: React.FC<Props> = ({ item }) => {
  const { userId } = useContext(DetailContext)

  return (
    <Container>
      <div className="wrapper">
        <p>記録が完了しました！</p>
        <div>
          <Button type="primary">
            <Link
              to={{
                pathname: `/detail`,
                search: `?user_id=${userId}`,
                state: item,
              }}
            >
              閉じる
            </Link>
          </Button>
        </div>
      </div>
    </Container>
  )
}

const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(10, 10, 10, 0.7);
  .wrapper {
    width: 90%;
    padding: 30px 10px;
    border-radius: 5px;
    text-align: center;
    background-color: #ffffff;
    p {
      padding: 30px 0;
      margin-bottom: 20px;
    }
  }
`

export default Complete
