import React, { useContext, useState, useEffect } from 'react'
import { DetailContext } from '../Context'
import styled from 'styled-components'
import Table from './Table'
import Graph from './Graph'
import { Button } from 'antd'
import { BarChartOutlined } from '@ant-design/icons'

const Home: React.FC = () => {
  const [showGraph, toggleGraph] = useState<boolean>(false)
  const { setTitle, answerData, getAnswerData } = useContext(DetailContext)

  const content: JSX.Element = showGraph ? (
    <Graph data={answerData} />
  ) : (
    <Table data={answerData} />
  )
  const btnText: string = showGraph ? 'リスト表示' : 'グラフ表示'

  useEffect(() => {
    setTitle('マインドチェック')
    getAnswerData()
  }, [])

  return (
    <Container>
      <div>
        {content}
        <Button
          danger
          block
          type="primary"
          style={{ marginTop: '10px' }}
          onClick={() => toggleGraph(!showGraph)}
        >
          <BarChartOutlined />
          {btnText}
        </Button>
      </div>
    </Container>
  )
}

const Container = styled.nav`
  height: 100%;
  min-height: calc(100vh - 110px);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

export default Home
