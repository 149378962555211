import React, { useContext, useEffect, useState } from 'react'
import { DetailContext } from '../Context'
import { withRouter } from 'react-router-dom'
import Complete from './Complete'
import axios from 'axios'
import styled, { css } from 'styled-components'
import { Form, Radio, Button } from 'antd'

const Check: React.FC = () => {
  const [sectionNum, setSectionNum] = useState<number>(1) // ページ番号
  const [formData, setFormData] = useState<Array<any>>([])
  const [answer, setAnswer] = useState<any>({})
  const [isValid, setIsValid] = useState<any>({ group1: true, group2: true })
  const [modal, setModal] = useState<boolean>(false)
  const { apiUrl, formInfo, userId, setTitle } = useContext(DetailContext)
  const questionText: any = { user_id: userId }

  useEffect(() => {
    setTitle('マインドチェック')
    if (formInfo.length !== 0) {
      formInfo.forEach((el: any) => {
        questionText[el.question_id] = null
      })
      const group: Array<Array<any>> = []
      const groupNum = Math.ceil(formInfo.length / 5)
      for (let i = 0; i < groupNum; i++) {
        const array: Array<any> = []
        for (let j = 0; j < 5; j++) {
          if (i * 5 + j < formInfo.length) {
            array.push(formInfo[i * 5 + j])
          }
        }
        group.push(array)
      }
      setFormData(group)
    }
  }, [formInfo])

  const [answers, setAnswers] = useState<any>(questionText)

  useEffect(() => {
    if (formInfo.length === 0 || sectionNum < 3) {
      return
    }
    let isFill = true
    formData[sectionNum - 3].forEach((el: any) => {
      if (!answers[el.question_id]) {
        isFill = false
      }
    })
    if (isFill) {
      setIsValid({ ...isValid, [`group${sectionNum}`]: true })
    }
  }, [answers])

  const prevBtn: JSX.Element = (
    <Button onClick={prevPage} style={{ width: '40%' }}>
      戻る
    </Button>
  )
  const submitBtn: JSX.Element = (
    <Button
      className="submit"
      type="primary"
      htmlType="submit"
      style={{ width: '40%' }}
    >
      登録
    </Button>
  )
  const nextBtn: JSX.Element = (
    <Button className="next" onClick={nextPage} style={{ width: '40%' }}>
      次へ
    </Button>
  )

  const section1: JSX.Element = (
    <section className="text-section">
      <p>
        5分間のアンケートに答えて
        <br />
        自身のマインド能力を
        <br />
        チェックしてみましょう。
      </p>
    </section>
  )
  const section2: JSX.Element = (
    <section className="text-section">
      <p>
        過去1ヶ月間の自身に
        <br />
        当てはまる内容に
        <br />
        チェックを付けていきましょう。
      </p>
    </section>
  )

  const section3: JSX.Element = (
    <>
      <FormSection>
        {formData[sectionNum - 3] &&
          formData[sectionNum - 3].map((data: any, i: number) => {
            return (
              <div key={i}>
                <p className="question">{data.question}</p>
                {!data.reverse && (
                  <Radio.Group
                    onChange={(event) => handleRadio(event, data)}
                    value={answers[data.question_id]}
                  >
                    <Radio value="4">そうだ</Radio>
                    <Radio value="3">まあそうだ</Radio>
                    <Radio value="2">ややちがう</Radio>
                    <Radio value="1">ちがう</Radio>
                  </Radio.Group>
                )}
                {data.reverse && (
                  <Radio.Group
                    onChange={(event) => handleRadio(event, data)}
                    value={answers[data.question_id]}
                  >
                    <Radio value="1">そうだ</Radio>
                    <Radio value="2">まあそうだ</Radio>
                    <Radio value="3">ややちがう</Radio>
                    <Radio value="4">ちがう</Radio>
                  </Radio.Group>
                )}
              </div>
            )
          })}
        <div className="num">
          {sectionNum - 2} / {formData.length}
        </div>
      </FormSection>
    </>
  )

  // function
  function handleRadio(event: any, data: any): void {
    new Promise<void>((resolve: (value?: any) => void) => {
      setAnswers({
        ...answers,
        user_id: userId,
        [data.question_id]: event.target.value,
      })
      resolve()
    })
  }

  function prevPage(): void {
    setSectionNum(sectionNum - 1)
  }
  function nextPage(): void {
    setSectionNum(sectionNum + 1)
    window.scroll({ top: 0 })
  }

  async function handleSubmit(event: any) {
    event.preventDefault()
    try {
      // 実施記録をDBへ登録
      const res = await axios.get(
        `${apiUrl}/db/php/mindfulness_set_data_info.php`,
        { params: answers }
      )
      shapeData(res.data[0])

      // キーワード通知
      await axios.get(`${apiUrl}/push_recording_button_message.php`, {
        params: {
          user_id: userId,
        },
      })
      setModal(true) // モーダル表示
    } catch (error) {
      console.log(error)
    }
  }

  function shapeData(item: any): void {
    let sum = 0
    for (let i = 1; i <= 30; i++) {
      const num: string = `00${i}`.slice(-3)
      sum = sum + Number(item[`data_${num}`])
    }
    item.sum = sum
    setAnswer(item)
  }

  // body
  return (
    <Container>
      <Form
        className="form"
        onSubmitCapture={(event: any) => handleSubmit(event)}
      >
        {sectionNum === 1 && section1}
        {sectionNum === 2 && section2}
        {sectionNum >= 3 && section3}
        <ButtonWrap isActive={isValid[`group${sectionNum}`]}>
          {sectionNum === 1 && null}
          {sectionNum >= 2 && prevBtn}
          {sectionNum <= 7 && nextBtn}
          {sectionNum === 8 && submitBtn}
        </ButtonWrap>
      </Form>
      {modal && <Complete item={answer} />}
    </Container>
  )
}

// style
const FormSection = styled.section`
  div {
    margin-bottom: 20px;
  }
  p {
    margin: 0;
  }
  .question {
    color: #555;
    font-weight: 600;
  }
  .ant-radio-group {
    height: 50px;
    display: flex;
    justify-content: space-between;
  }
  label {
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
  }
  .num {
    font-size: 18px;
    text-align: center;
  }
`
const Container = styled.nav`
  .text-section {
    height: calc(100vh - 160px);
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-size: 18px;
  }
  .form {
    height: 100%;
    min-height: calc(100vh - 110px);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
`

const ButtonWrap = styled.div<{ isActive: boolean }>`
  width: 100%;
  max-width: 500px;
  display: flex;
  justify-content: space-around;
  ${(props) =>
    props.isActive
      ? ''
      : css`
          .next {
            color: #fff !important;
            background-color: #ccc;
            pointer-events: none;
          }
          .submit {
            background-color: #ccc !important;
            pointer-events: none;
          }
        `}
`
export default withRouter(Check)
