import React, { useContext, useState, useEffect } from 'react'
import { DetailContext } from '../Context'
import { Link, useHistory } from 'react-router-dom'
import axios from 'axios'
import styled from 'styled-components'
import { Form, Radio, Select, Button, message } from 'antd'
const { Option } = Select

const Setting: React.FC = () => {
  const { apiUrl, userId, setTitle, userData, getMemberData } =
    useContext(DetailContext)
  const [user, setUser] = useState<any>(userData)
  const history = useHistory()

  useEffect(() => {
    setTitle('登録変更')
  }, [])

  useEffect(() => {
    setUser(userData)
  }, [userData])

  async function handleSubmit() {
    if (!user.gender || !user.age || !user.company_id) {
      return
    }
    try {
      await axios.get(`${apiUrl}/db/php/mindfulness_set_member_info.php`, {
        params: user,
      })
      message.success('ユーザー情報を更新しました')
      getMemberData()
      return history.push(`/?user_id=${userId}`)
    } catch (error) {
      console.log(error)
    }
  }

  async function deleteUser() {
    const check = window.confirm('ユーザー情報を削除しますか？')
    if (!check) {
      return
    }
    try {
      await axios.get(
        `${apiUrl}/db/php/mindfulness_delete_member_info.php?user_id=${userId}`
      )
      await axios.get(`${apiUrl}/push_entry_message.php?user_id=${userId}`)
      message.success('ユーザー情報を初期化しました')
      getMemberData()
      return history.push(`/entry/?user_id=${userId}`)
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <div>
      <Form onFinish={handleSubmit}>
        <Table>
          <tbody>
            <tr>
              <th>性別</th>
              <td>
                <Form.Item style={{ marginBottom: '0' }}>
                  <Radio.Group
                    onChange={(event: any) =>
                      setUser({ ...user, gender: event.target.value })
                    }
                    value={user.gender}
                  >
                    <Radio value="男性">男性</Radio>
                    <Radio value="女性">女性</Radio>
                    <Radio value="その他">その他</Radio>
                  </Radio.Group>
                </Form.Item>
              </td>
            </tr>

            <tr>
              <th>年代</th>
              <td>
                <Form.Item style={{ marginBottom: '0' }}>
                  <Select
                    onChange={(event: any) => setUser({ ...user, age: event })}
                    value={user.age}
                  >
                    <Option value="10代">10代</Option>
                    <Option value="20代">20代</Option>
                    <Option value="30代">30代</Option>
                    <Option value="40代">40代</Option>
                    <Option value="50代">50代</Option>
                    <Option value="60代">60代</Option>
                    <Option value="70代以上">70代以上</Option>
                  </Select>
                </Form.Item>
              </td>
            </tr>

            <tr>
              <th>VR ID</th>
              <td>{user.company_id}</td>
            </tr>
          </tbody>
        </Table>

        <ButtonWrap>
          <Button block type="primary" htmlType="submit">
            更新
          </Button>
        </ButtonWrap>
      </Form>

      <ButtonWrap>
        <Button block>
          <Link to={`/?user_id=${userId}`}>戻る</Link>
        </Button>
      </ButtonWrap>

      <ButtonWrap>
        <Button
          block
          type="link"
          danger
          style={{ borderColor: 'transparent' }}
          onClick={deleteUser}
        >
          データ初期化
        </Button>
      </ButtonWrap>
    </div>
  )
}

const Table = styled.table`
  width: 100%;
  tr {
    height: 50px;
    vertical-align: middle;
  }
  th {
    width: 30%;
    font-weight: normal;
    text-align: left;
  }
  td {
    width: 70%;
  }
`
const ButtonWrap = styled.div`
  width: 80%;
  margin: 0 auto;
  margin-top: 10px;
`

export default Setting
