import React, { useContext } from 'react'
import { DetailContext } from '../Context'
import Caution from './Caution'
import styled from 'styled-components'

const Header: React.FC = () => {
  const { title, showCaution } = useContext(DetailContext)

  return (
    <Container>
      <h1>{title}</h1>
      {showCaution && <Caution />}
    </Container>
  )
}

const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #a3d9a6;
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.2);
  z-index: 100;
  h1 {
    margin: 0;
    color: #ffffff;
    font-size: 20px;
    font-weight: 400;
    text-align: center;
  }
`
export default Header
